import React from 'react';
import styled from 'styled-components';
import {
  AvatarCard,
  AvatarContainer,
  Avatar,
  Title,
  Caption,
  Description,
} from '../components/cards';
import SEO from '../components/seo';
import Article from '../components/article';
import PageTitle from '../components/page-title';
import { decodeHtml } from '../helpers';
import ConditionalRenderer from '../components/conditionalRenderer';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  grid-gap: 2rem;
  place-items: center;
`;

export default function Idolos(props) {
  const {
    pageContext: { data },
  } = props;

  const allIdols = data.map((idol) => {
    const { camposIdolo, slug } = idol;
    const { avatar, frase, nome, nomeCompleto } = camposIdolo;
    return (
      <AvatarCard to={`/idolos/${slug}`} key={slug}>
        <AvatarContainer>
          <Avatar src={avatar?.sourceUrl} alt={nome} />
          <figcaption>
            <Title>{decodeHtml(nome)}</Title>
            <Description>{decodeHtml(nomeCompleto)}</Description>
          </figcaption>
        </AvatarContainer>
        <ConditionalRenderer
          condition={frase}
          renderOnSuccess={() => <Caption>{decodeHtml(frase)}</Caption>}
        />
      </AvatarCard>
    );
  });
  return (
    <>
      <SEO title="Ídolos" />
      <Article>
        <PageTitle>Ídolos</PageTitle>
        <Grid>{allIdols}</Grid>
      </Article>
    </>
  );
}
